<template>
  <div class="relative flex-shrink-0 ml-2">
    <VDropdown v-if="user" :placement="'bottom-end'" :distance="4">
      <button
        @click.prevent
        class="flex text-sm hover:bg-neutral-800 hover:text-white items-center py-0.5 px-2 rounded-md focus:outline-none text-neutral-100 border-transparent group border focus:border-neutral-600 cursor-pointer"
      >
        <div class="flex text-sm rounded-full">
          <div
            class="items-center hidden pr-2 text-sm font-medium md:flex"
            v-if="project_org_name"
          >
            {{ project_org_name }}
          </div>
          <MemberAvatar v-bind:member="user" />
        </div>
      </button>
      <template #popper="{ hide }">
        <!-- Management Link -->
        <div>
          <a
            :href="managementAccountURL"
            class="flex items-center justify-between px-4 py-2 space-x-2 text-sm leading-5 border-b border-neutral-600 hover:text-neutral-100 text-neutral-400 hover:bg-neutral-600 hover:border-neutral-600 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
          >
            <div class="flex-1 min-w-0">
              <div class="text-sm font-medium truncate text-neutral-100">
                {{ user.first_name + ' ' + user.last_name }}
              </div>
              <div class="text-xs font-medium truncate text-neutral-400">
                {{ user.email }}
              </div>
            </div>
            <div class="flex text-sm rounded-full">
              <MemberAvatar v-bind:member="user" v-bind:variable-size="8" />
            </div>
          </a>
          <!-- Org Link -->
          <div class="flex-1 min-w-0" v-if="project">
            <a
              :href="managementOrgUrl"
              class="flex justify-between items-center px-4 py-1.5 border-b border-neutral-600 w-full hover:text-neutral-100 text-neutral-400 hover:bg-neutral-600"
            >
              <div class="min-w-0">
                <div
                  class="text-sm font-medium truncate text-neutral-100"
                  role="none"
                >
                  {{ project.org_name }}
                </div>
                <div
                  class="text-xs font-medium truncate text-neutral-400"
                  role="none"
                >
                  {{ project.org_role }}
                </div>
              </div>
              <fa-icon
                icon="arrow-right"
                aria-hidden="true"
                class="ml-2 text-neutral-300 fa-fw"
              />
            </a>
          </div>

          <!-- Sub Actions -->
          <div class="p-1">
            <a
              :href="managementOrgSwitchUrl"
              class="flex items-center justify-between px-4 py-2 text-sm border border-transparent rounded-md text-neutral-400 hover:text-neutral-100 hover:bg-neutral-600 hover:border-neutral-600"
            >
              Switch Organisation
              <fa-icon
                icon="shuffle"
                aria-hidden="true"
                class="ml-2 text-green-500 fa-fw"
              />
            </a>

            <a
              href="https://help.disguise.one/en/Content/Cloud/Disguise-Cloud/Disguise-cloud-overview.html"
              target="_blank"
              class="flex items-center justify-between px-4 py-2 text-sm leading-5 border border-transparent rounded-md hover:text-neutral-100 text-neutral-400 hover:bg-neutral-600 hover:border-neutral-600"
              role="menuitem"
              >User Guide

              <fa-icon
                icon="arrow-up-right-from-square"
                aria-hidden="true"
                class="ml-2 text-neutral-300 fa-fw"
            /></a>
            <router-link
              to="/logout"
              class="flex items-center justify-between px-4 py-2 text-sm leading-5 border border-transparent rounded-md text-neutral-400 hover:bg-neutral-600 hover:border-neutral-600 hover:text-neutral-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
              >Sign Out</router-link
            >
          </div>
        </div>
      </template>
    </VDropdown>
    <div v-else-if="isAuthed">
      <slot name="guestuser">
        <router-link
          to="/logout"
          class="inline-flex items-center justify-center px-3 py-2 text-sm font-medium leading-4 bg-green-600 border border-transparent rounded-md shadow-sm select-none text-neutral-100 ring-offset-0 focus:ring-green-500 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-1"
        >
          <span>Sign Out</span>
        </router-link>
      </slot>
    </div>

    <div class="p-1 space-x-2" v-else>
      <slot name="guestuser">
        <a
          href="#"
          class="inline-flex items-center justify-center w-full px-3 py-2 text-sm font-medium leading-4 bg-transparent border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 text-neutral-100 ring-offset-neutral-100/40 ring-transparent focus:ring-offset-2 hover:bg-white/5"
        >
          <span>Create Account</span>
        </a>
        <a
          :href="managementLoginURL"
          class="inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span>Login</span>
        </a>
      </slot>
    </div>
  </div>
</template>
<script>
import redirectToOnboarding from '@mixins/redirect-to-onboarding'

const UserProfile = () => import('@modals/UserProfile')
const MemberAvatar = () => import('@components/members/MemberAvatar')

export default {
  name: 'NavBarUser',

  mixins: [redirectToOnboarding],

  components: {
    MemberAvatar
  },
  props: {
    project: {
      type: [Object, null],
      default: null
    }
  },

  data: () => ({
    enableAppPreferences: false
  }),

  computed: {
    userPhotoUrl() {
      if (this.user) {
        return this.user.photo_url
      }
      return ''
    },
    user() {
      return this.$store.getters.user
    },
    isAuthed() {
      return this.$store.getters['auth/isAuthed']
    },
    appNotificationsEnabled() {
      return this.$store.getters['app/getFlag']('appNotificationsEnabled')
    },
    managementAccountURL() {
      return process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL + '/account'
    },
    managementLoginURL() {
      return process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL + '/login'
    },

    project_org_name() {
      if (this.project) return this.project.org_name
      return null
    },
    managementOrgUrl() {
      if (this.project)
        return (
          process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL +
          '/orgs/' +
          this.project.org_id
        )
      return this.managementOrgSwitchUrl
    },

    managementOrgSwitchUrl() {
      return process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL + '/current-org'
    }
  },

  methods: {
    onProfileModal() {
      this.$bus.$emit('overlay:modal', {
        component: UserProfile,
        props: {}
      })
    }
  }
}
</script>
