export default {
  computed: {
    needsOnboarding() {
      return this.$store.getters['doesUserNeedOnboardingPreviz']
    }
  },

  methods: {
    redirectToOnboarding() {
      // Same failsafe as spaces, but this is a bit more complex
      // because we need to retain the query params and the next route
      // for the editor. TL;DR - If it's the editor, we grab the entire route
      // otherwise we just grab the query params
      if (this.needsOnboarding && this.$route.name !== 'user-onboarding') {
        this.$router.push({
          name: 'user-onboarding',
          query:
            this.$route.name === 'editor' ||
            Object.keys(this.$route.query).length > 0
              ? { next: this.$route.fullPath }
              : this.$route.query
        })
      }
    }
  },

  watch: {
    needsOnboarding() {
      this.redirectToOnboarding()
    }
  },

  mounted() {
    this.redirectToOnboarding()
  }
}
